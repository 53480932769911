import React, {useEffect, useState} from 'react';
import {Typography, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress} from '@material-ui/core';
import axios from 'axios';

interface JsonTableProps {
    data: any;
}

interface ExternalSyncData {
    system: string;
    imported: string;
    action: string;
    status: string;
    invited_carrier_email: string;
    synced_for_email: string;
    packet_factoring_company: string;
    packet_payout_days: string;
    input_data: Record<string, any>;
    tags: Record<string, any>;
}

interface ExternalSyncDataTableProps {
    userId: string;
}


const JsonTable: React.FC<JsonTableProps> = ({data}) => {
    const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
    const [isTableVisible, setIsTableVisible] = useState(false); // State for table visibility

    const toggleRow = (rowKey: string) => {
        setExpandedRows((prev) => ({
            ...prev,
            [rowKey]: !prev[rowKey],
        }));
    };

    const renderRow = (row: any, rowKey: string, level: number = 0) => {
        return Object.entries(row).map(([key, value]) => {
            const isNested = typeof value === "object" && value !== null;
            const currentRowKey = `${rowKey}-${key}`; // Unique row identifier

            return (
                <React.Fragment key={currentRowKey}>
                    <tr
                        className={`border-b ${isNested ? "cursor-pointer" : ""}`}
                        onClick={() => isNested && toggleRow(currentRowKey)}
                    >
                        <td
                            className="border px-4 py-2"
                            style={{paddingLeft: `${level * 20}px`}}
                        >
                            {isNested ? (
                                <span className="flex items-center cursor-pointer">
                  <span className="mr-2 text-xl" style={{cursor: "pointer"}}>
                    {expandedRows[currentRowKey] ? "▼" : "▶"}
                  </span>
                  <strong>{key}</strong>
                </span>
                            ) : (
                                <strong>{key}</strong>
                            )}
                        </td>
                        <td className="border px-4 py-2">
                            {!isNested ? (value !== null && value !== undefined ? String(value) : "") : ""}
                        </td>
                    </tr>

                    {isNested && expandedRows[currentRowKey] && (
                        <tr>
                            <td colSpan={2} className="p-0">
                                <table className="w-full border-l border-gray-300">
                                    <tbody>{renderRow(value, currentRowKey, level + 1)}</tbody>
                                </table>
                            </td>
                        </tr>
                    )}
                </React.Fragment>
            );
        });
    };

    return (
        <div className="overflow-x-auto">
            <button
                onClick={() => setIsTableVisible(!isTableVisible)}
                className="mb-2 px-4 py-2 bg-blue-500 text-white rounded-lg"
            >
                {isTableVisible ? "Hide" : "Show"}
            </button>

            {isTableVisible && (
                <table className="min-w-full border border-gray-300">
                    <tbody>
                    {Array.isArray(data)
                        ? data.map((item, index) => renderRow(item, `${index}`))
                        : renderRow(data, "root")}
                    </tbody>
                </table>
            )}
        </div>
    );
};

const ExternalSyncDataTable: React.FC<ExternalSyncDataTableProps> = ({userId}) => {
    const [externalSyncData, setExternalSyncData] = useState<ExternalSyncData[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [noDataMessage, setNoDataMessage] = useState<string | null>(null);

    const fetchExternalSyncData = async (userId: string) => {
        setLoading(true);
        setError(null);
        setNoDataMessage(null);
        try {
            const response = await axios.get(`/admin/external_sync_carrier_data/?userId=${encodeURIComponent(userId)}`);
            const responseData = response.data;

            if (responseData.data === 'No external packets found for this user') {
                setNoDataMessage(responseData.data);
            } else {
                setExternalSyncData(responseData);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                console.error("Error fetching external sync data", error);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userId) {
            fetchExternalSyncData(userId);
        }
    }, [userId]);

    return (
        <div>
            {loading ? (
                <CircularProgress/>
            ) : error ? (
                <Typography variant="h6" color="error">{error}</Typography>
            ) : noDataMessage ? (
                <Typography variant="h6">{noDataMessage}</Typography>
            ) : (
                externalSyncData.map((item, index) => (
                    <div key={index}>
                        <Typography variant="h6">{item.imported}</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>System</TableCell>
                                    <TableCell>Action</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>IC email</TableCell>
                                    <TableCell>Synced For Email</TableCell>
                                    <TableCell>Input Factoring Company</TableCell>
                                    <TableCell>Input Payout Days</TableCell>
                                    <TableCell>Input Data</TableCell>
                                    <TableCell>Tags</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{item.system}</TableCell>
                                    <TableCell>{item.action}</TableCell>
                                    <TableCell>{item.status}</TableCell>
                                    <TableCell>{item.invited_carrier_email}</TableCell>
                                    <TableCell>{item.synced_for_email}</TableCell>
                                    <TableCell>{item.packet_factoring_company}</TableCell>
                                    <TableCell>{item.packet_payout_days}</TableCell>
                                    <TableCell>
                                        <JsonTable data={item.input_data}/>
                                    </TableCell>
                                    <TableCell>
                                        <JsonTable data={item.tags}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                ))
            )}
        </div>
    );
};

export default ExternalSyncDataTable;
