import { Button, Grid, MenuItem } from "@material-ui/core";
import React, { MouseEvent, ChangeEventHandler } from "react";

import CustomTextField from "../../../../components/inputs/CustomTextField";
import ListSubheader from '@material-ui/core/ListSubheader';

interface Props {
  contractType: string;
  handleSetContractType: (event: MouseEvent) => void;
  handleContractTypeChange: ChangeEventHandler<HTMLInputElement>;
  disableSubmitButton?: false;
}

const SetContractType = ({
  contractType,
  handleSetContractType,
  handleContractTypeChange,
  disableSubmitButton
}: Props): JSX.Element => {
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      spacing={32}
      style={{ paddingRight: "32px" }}
    >
      <Grid item xs={2}>
        <CustomTextField
          fullWidth
          select
          name="contractType"
          label="Contract Type"
          value={contractType}
          onChange={handleContractTypeChange}
        >
          <ListSubheader>Standard Funding Requests</ListSubheader>
            <MenuItem value="STD_BROKER">Factored</MenuItem>
          <ListSubheader>Non Factored Funding Requests</ListSubheader>
            <MenuItem value="NON_FACTORED_STANDARD">Non-Factored Pay Carrier</MenuItem>
            <MenuItem value="NON_FACTORED_BILL_OUT">Non-Factored Bill Out Only</MenuItem>
        </CustomTextField>
      </Grid>
      <div style={{ padding: 20 }}>
        <Button
          variant="contained"
          color="primary"
          mini
          onClick={handleSetContractType}
          disabled={disableSubmitButton}
        >
          Set Contract Type
        </Button>
      </div>
    </Grid>
  );
}

export default SetContractType;
