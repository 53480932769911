import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { get } from "lodash";

import api from "../../../../api";
import { openDialog, getDialog, openSnackbar, closeDialog } from "../../../../modules/ui";
import {
  fetchPaymentProfile,
  getPaymentProfile,
  updatePaymentProfile,
  fetchUser,
  getUser,
  fetchAttachments,
  uploadAttachments,
  getAttachments,
  fetchHistory,
  getHistory,
  deleteAttachment as deleteProfileAttachment
} from "../../../../modules/payment";
import Can from "../../../../components/Can";
import Link from "../../../../components/Link";
import InternalLayout from "../../../../components/layouts/InternalLayout";
import FMCSALink from "../../../../components/FMCSA";
import SaveButton from "./components/SaveButton";
import NavigationTabs from "../../../../components/NavigationTabs";
import tabs from "../tabs";
import BusinessInfoCard from "./components/BusinessInfoCard";
import ApprovalStatusCard from "./components/ApprovalStatusCard";
import OwnerInfoCard from "./components/OwnerInfoCard";
import CarrierInfoCard from "./components/CarrierInfoCard";
import VerificationInfo from "./components/VerificationInfo";
import ClientProfileButton from "./components/ClientProfileButton";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import EditPaymentButton from "../../FactorClient/Profile/components/buttons/EditPaymentButton";
import EditPaymentMethodDialog from "../../FactorClient/Profile/components/dialogs/EditPaymentMethodDialog";
import InternalUserNotes from "../../../../components/InternalUserNotes";
import AuditTrail from "../../../../components/AuditTrail";
import ExternalSyncDataTable from '../../../../components/ExternalSyncDataTable'; // Import the ExternalSyncDataTable component

import BillingNotes from "../../../../components/BillingNotes";
import SendBankLinkButton from "../../../../components/SendBankLinkButton";
import WithSimilarPaymentProfile from "../WithSimilarPaymentProfile";
import states from '../../../../helpers/states';
import { Select } from '../../../../components/inputs/BoldInput';
import AuthorityCoBrokeringStatusCard from './components/AuthorityCoBrokeringStatusCard';

export default function Profile({
  index,
  handleChange: handleTabChange,
  ...props
}) {
  const id = get(props, ["match", "params", "id"], "");
  const dispatch = useDispatch();
  const [externalSyncData, setExternalSyncData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count] = WithSimilarPaymentProfile(id);
  const paymentProfile = useSelector(getPaymentProfile(id));
  const factorCompany = get(paymentProfile, "factoring_company", {})  || {};
  const userId = get(paymentProfile, "user");
  const user = useSelector(getUser(userId));
  const [verification, setVerification] = useState({});
  const dialog = useSelector(getDialog);
  const history = useSelector(getHistory);
  async function deleteAttachment(attachmentId) {
    await dispatch(deleteProfileAttachment(id, attachmentId));
    await dispatch(fetchAttachments(id));
  }

  function updateProfile(profile) {
    dispatch(updatePaymentProfile(id, profile));
  }

  function handleCloseDialog() {
    dispatch(closeDialog())
  }

  function handleOpenDialog(...args) {
    dispatch(openDialog(...args))
  }

  useEffect(() => {
    dispatch(fetchHistory(id));
  }, [id]);

  useEffect(() => {
    async function fetchVerification() {
      const ver = await api.groups.getVerificationInfo(id);
      setVerification(ver);
    }
    fetchVerification();
  }, [id]);

  useEffect(() => {
    dispatch(fetchPaymentProfile(id));
  }, [id]);
  useEffect(() => {
    if (userId) {
      dispatch(fetchUser(userId));
    }
  }, [userId]);
  useEffect(() => {
    dispatch(fetchAttachments(id));
  }, [id]);
  const attachments = useSelector(getAttachments(id));
  const handleEditPayment = useCallback(
    () => dispatch(openDialog("edit-payment")),
    [dispatch]
  );

  return (
    <Can
      perform="admin-payments:view"
      yes={() => (
        <InternalLayout title="Payment Profile">
          <Formik
            enableReinitialize
            initialValues={{
              attachments: [],
              autoSendPaymentEmail: paymentProfile.auto_send_payment_email,
              remittanceEmail: paymentProfile.remittance_email,
              carrierContactName: user.contact_name,
              carrierPhone: user.contact_phone,
              carrierCreatedAt: user.created,
              carrierLastLogin: user.last_login,
              status: paymentProfile.status,
              firstName: paymentProfile.owner_name_first,
              lastName: paymentProfile.owner_name_last,
              address: paymentProfile.owner_address_street_one,
              streetTwo: paymentProfile.owner_address_street_two,
              city: paymentProfile.owner_address_city,
              state: paymentProfile.owner_address_state,
              zip: paymentProfile.owner_address_zip,
              cellPhone: paymentProfile.owner_phone_number_cell,
              homePhone: paymentProfile.owner_phone_number_home,
              email: paymentProfile.owner_email,
              dob: paymentProfile.owner_date_of_birth,
              ownerSSN: paymentProfile.owner_social_number,
              lienStatus: paymentProfile.owner_tax_lien_status,
              businessLegalName: paymentProfile.company_name,
              businessAddress: paymentProfile.company_address_street_one,
              businessStreetTwo: paymentProfile.company_address_street_two,
              businessCity: paymentProfile.company_address_city,
              businessState: paymentProfile.company_address_state,
              businessZip: paymentProfile.company_address_zip,
              businessType: paymentProfile.company_type,
              businessMc: paymentProfile.company_mc,
              businessDot: paymentProfile.company_dot,
              businessStateIncorporated:
                paymentProfile.company_state_incorporated,
              businessEin: paymentProfile.company_tax_id,
              businessCurrentFactoringCompany: get(paymentProfile, [
                "factoring_company",
                "name"
              ]),
              businessCurrentFactoringCompanyId: get(paymentProfile, [
                "factoring_company",
                "id"
              ]),
              enable_discount: paymentProfile.enable_discount,
              needAccountInfo: paymentProfile.needs_account_info || false,
              needFactorInfo: paymentProfile.needs_factor_info || false,
              needNOA: paymentProfile.needs_noa || false,
              needVerifiedIdentity:
                paymentProfile.needs_verified_identity || false,
              mailingAddressOne: get(paymentProfile, 'mailing_address.street_one', ''),
              mailingAddressTwo: get(paymentProfile, 'mailing_address.street_two', ''),
              mailingAddressCity: get(paymentProfile, 'mailing_address.city', ''),
              mailingAddressState: get(paymentProfile, 'mailing_address.state', ''),
              mailingAddressZip: get(paymentProfile, 'mailing_address.zip', ''),
              mailingAddressCountry: get(paymentProfile, 'mailing_address.country', ''),
              enablePayViaCheck: paymentProfile.enable_pay_via_check || false,
              authority: paymentProfile.authority || ""
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                await dispatch(uploadAttachments(id, values.attachments));
                await dispatch(
                  updatePaymentProfile(id, {
                    auto_send_payment_email: values.autoSendPaymentEmail,
                    status: values.status,
                    company_name: values.businessLegalName,
                    company_address_street_one: values.businessAddress,
                    company_address_street_two: values.businessStreetTwo,
                    company_address_city: values.businessCity,
                    company_address_state: values.businessState,
                    company_address_zip: values.businessZip,
                    company_type: values.businessType,
                    company_mc: values.businessMc,
                    company_dot: values.businessDot,
                    company_state_incorporated:
                      values.businessStateIncorporated,
                    company_tax_id: values.businessEin,
                    owner_name_first: values.firstName,
                    owner_name_last: values.lastName,
                    owner_address_street_one: values.address,
                    owner_address_street_two: values.streetTwo,
                    owner_address_city: values.city,
                    owner_address_state: values.state,
                    owner_address_zip: values.zip,
                    owner_phone_number_cell: values.cellPhone,
                    owner_phone_number_home: values.homePhone,
                    owner_email: values.email,
                    owner_date_of_birth: values.dob,
                    owner_social_number: values.ownerSSN,
                    owner_tax_lien_status: values.lienStatus,
                    enable_discount: values.enable_discount,
                    remittance_email: values.remittanceEmail,
                    needs_account_info: values.needAccountInfo,
                    needs_factor_info: values.needFactorInfo,
                    needs_noa: values.needNOA,
                    needs_verified_identity: values.needVerifiedIdentity,
                    mailing_address: {
                      street_one: values.mailingAddressOne,
                      street_two: values.mailingAddressTwo,
                      city: values.mailingAddressCity,
                      state: values.mailingAddressState,
                      zip: values.mailingAddressZip,
                      country: values.mailingAddressCountry,
                    },
                    enable_pay_via_check: values.enablePayViaCheck,
                    authority: values.authority
                  })
                );
                dispatch(openSnackbar("success", "Saved Payment Profile!"));
              } catch (err) {
                dispatch(openSnackbar("error", err));
              } finally {
                resetForm();
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting,
              setFieldTouched
            }) => (
              <React.Fragment>
                <Grid
                  container
                  direction="column"
                  spacing={24}
                  justify="flex-start"
                  alignItems="stretch"
                  style={{
                    flexWrap: "none"
                  }}
                >
                  <Grid item>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Grid container alignItems="center" spacing={16}>
                              <Grid item>
                                <Typography variant="h6">
                                  {get(
                                    paymentProfile,
                                    "company_name",
                                    "Payment Profile"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Link to={`/admin/users/${userId}/profile`}>
                                  User Profile
                                </Link>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <FMCSALink
                              mc={values.businessMc}
                              dot={values.businessDot}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={16} alignItems="center">
                          {get(user, "factoring_id") && (
                            <Grid item>
                              <Link
                                to={`/admin/factorclients/${get(
                                  user,
                                  "factoring_id"
                                )}/profile`}
                              >
                                <ClientProfileButton />
                              </Link>
                            </Grid>
                          )}
                          <Grid item>
                            <SendBankLinkButton
                              userId={userId}
                              source="payment_profile"
                            />
                          </Grid>
                          <Grid item>
                            <EditPaymentButton onClick={handleEditPayment} />
                          </Grid>
                          <Grid item>
                            <SaveButton
                              onClick={() => handleSubmit()}
                              disabled={isSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <NavigationTabs tabs={tabs(id, count)} value={0} />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="stretch"
                    spacing={16}
                  >
                    <Grid item xs={4} container direction="column" spacing={16}>
                      <Grid item>
                        <ApprovalStatusCard
                          title="Carrier Approval Status"
                          status={values.status}
                          name="status"
                          handleChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexGrow: 1
                        }}
                      >
                        <CarrierInfoCard
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          paymentProfile={paymentProfile}
                          paymentProfileId={id}
                          factorCompany={factorCompany}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={4} spacing={16} direction="column">
                      <Grid item>
                        <AuthorityCoBrokeringStatusCard
                           title="Broker Authority"
                           value={values.authority}
                           name="authority"
                           handleChange={handleChange}
                           handleBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item>
                          <BusinessInfoCard
                            style={{ height: "100%" }}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                          />
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <OwnerInfoCard
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        attachments={attachments}
                        deleteAttachment={deleteAttachment}
                        openDialog={handleOpenDialog}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Paper style={{ width: "100%" }}>
                      <Tabs
                        value={index}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        <Tab label="Billing Notes" />
                        <Tab label="Internal User Notes" />
                        <Tab label="Verification Info" />
                        <Tab label="Audit Log" />
                        <Tab label="External Sync Data" />
                      </Tabs>
                      <div style={{ padding: 32, width: "100%" }}>
                        {index === 0 && <BillingNotes paymentProfileId={id} />}
                        {index === 1 && <InternalUserNotes userId={userId} />}
                        {index === 2 && (
                          <VerificationInfo verification={verification} />
                        )}
                        {index === 3 && <AuditTrail auditLog={history} />}
                        {index === 4 && (<ExternalSyncDataTable userId={userId} />)}
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Formik>
          {dialog.variant === "edit-payment" && (
            <EditPaymentMethodDialog userId={userId} user={user} updateProfile={updateProfile} />
          )}
          {dialog.variant === "confirm" && (
            <ConfirmDialog
              open={dialog.show}
              message={dialog.message}
              handleConfirm={async () => {
                deleteAttachment(dialog.data.id)
                .finally(() => {
                  handleCloseDialog();
                });
              }}
              handleExit={() => handleCloseDialog()}
            />
          )}
        </InternalLayout>
      )}
      no={() => <Redirect to="/" />}
    />
  );
}
